import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import TopCard, { CustomCardAction } from "./TopCard";
import { useTranslation } from "react-i18next";
import { Attack, Overview, useApi } from "@blindspot/bspot-api-lib";
import useService from "../hooks/useService";
import { useRecoilValue } from "recoil";
import { apiClient } from "../state/atoms";

interface AttackHistoryErrCardProps {
  overview: Overview;
  loading: boolean;
}

export default function AttackHistoryErrCard({ overview, loading }: AttackHistoryErrCardProps) {
  const { t } = useTranslation("index", { keyPrefix: "cards" });

  const service = useService();
  const api = useRecoilValue(apiClient);

  return (
    <TopCard
      isLoading={loading}
      status="attack"
      overline={t("under_attack.destination.title")}
      h5={`${overview?.ongoing_attacks_target_ips} ${t("under_attack.destination.destination", { count: overview?.ongoing_attacks_target_ips })}`}
      icon={<WarningAmberOutlinedIcon color="error" fontSize="large" sx={{ alignSelf: "flex-start" }} />}
    >
      <CustomCardAction linkToIdElement={{ text: t("common.show_all"), href: "#mitigations", color: "text.primary" }} />
    </TopCard>
  );
}
