import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Fetch from "i18next-fetch-backend";

i18n
  .use(Fetch)
  .use(initReactI18next)
  .init({
    fallbackLng: false,
    // lng: "en",
    lng: "en",
    backend: {
      loadPath: `/langs/{{lng}}.{{ns}}.json?${import.meta.env.VITE_LANG_HASH || "devel"}`,
    },
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  })
  .then();

export default i18n;
