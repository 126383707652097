import {
  Box,
  Divider,
  Drawer,
  useTheme,
  IconButton,
  ListItemIcon,
  ListItemText,
  List,
  ListItemButton,
  Collapse,
  ListItem,
  useMediaQuery,
} from "@mui/material";
import Logo from "./Logo";
import HomeIcon from "@mui/icons-material/Home";
import { navigate } from "../utils/navigation";
import AnalyticsIcon from "@mui/icons-material/Analytics";
import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";
/*
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
import SupportIcon from "@mui/icons-material/Support";
import ListAltIcon from "@mui/icons-material/ListAlt";
 */
import React, { MouseEventHandler, useState } from "react";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import HistoryIcon from "@mui/icons-material/History";
// import BugButton from "./Buttons/BugButton";

interface MenuDrawerProps {
  drawer: boolean;
  setDrawer: React.Dispatch<React.SetStateAction<boolean>>;
}

export default function MenuDrawer({ drawer, setDrawer }: MenuDrawerProps) {
  const theme = useTheme();
  const shouldBePermanent = useMediaQuery(theme.breakpoints.up("xxl"));

  return (
    <>
      <Box sx={{ overflow: "auto" }}>
        <Drawer
          variant={shouldBePermanent ? "permanent" : "persistent"}
          anchor="left"
          open={drawer}
          onClose={() => setDrawer(false)}
          sx={{
            ["& .MuiBackdrop-root"]: { backgroundColor: "transparent" },
            flexShrink: 0,
            ["& .MuiDrawer-paper"]: { boxSizing: "border-box", borderWidth: 0 },
          }}
          PaperProps={{
            elevation: 16,
            sx: {
              top: 64,
              zIndex: -99999,
              minWidth: "22.5rem",
            },
          }}
        >
          <Box sx={{ overflow: "auto" }} data-testid="menu">
            {/*<Toolbar />*/}
            <Box
              sx={{
                width: "100%",
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
                flexFlow: "center",
                alignItems: "center",
                paddingY: "1.5rem",
                paddingX: "1.875rem",
              }}
            >
              <Logo width="120" height="27.6979" />
              <IconButton
                onClick={() => setDrawer(false)}
                sx={{
                  display: { xs: "inline-flex", xxl: "none" },
                }}
              >
                <ChevronLeftIcon sx={{ color: "action" }} />
              </IconButton>
            </Box>
            <Divider sx={{ marginX: "0.625rem" }} />
            <List sx={{ width: "100%" }} component="nav">
              <ItemList
                onClick={() => {
                  setDrawer(false);
                  navigate("/");
                }}
                navigate="/"
                text="Dashboard"
                icon={<HomeIcon />}
              />
              <ItemList
                onClick={() => {
                  setDrawer(false);
                  navigate("/#attack_history");
                }}
                navigate="/#attack_history"
                text="Threat Campaigns"
                icon={<HistoryIcon />}
              />
              <ItemList
                onClick={() => {
                  setDrawer(false);
                  navigate("/#connections");
                }}
                navigate="/#connections"
                text="Connections"
                icon={<AnalyticsIcon />}
              />
              <ItemList
                onClick={() => {
                  setDrawer(false);
                  navigate("/#ip_prefixes");
                }}
                navigate="/#ip_prefixes"
                text="Received IP Prefixes"
                icon={<FormatListBulletedIcon />}
              />
              {/* <MultipleItemList text="Logs" icon={<FormatListBulletedIcon />}>
                <ItemList
                  onClick={() => {
                    setDrawer(false);
                    navigate("/attack_log");
                  }}
                  navigate="/attack_log"
                  text="Attack log"
                  icon={<HistoryIcon />}
                  paddingLeft={5}
                />
                <ItemList
                  onClick={() => {
                    setDrawer(false);
                    navigate("/#ip_prefixes");
                  }}
                  navigate="/#ip_prefixes"
                  text="BGP Received prefixes log"
                  icon={<FormatListBulletedIcon />}
                  paddingLeft={5}
                />
                <ItemList
                  onClick={() => {
                    setDrawer(false);
                    navigate("/all-ip-prefixes");
                  }}
                  navigate="/all-ip-prefixes"
                  text="Customer prefixes log"
                  icon={<FormatListBulletedIcon />}
                  paddingLeft={5}
                />
              </MultipleItemList> */}
            </List>
            <Divider sx={{ marginX: "0.625rem" }} />
            {/* <ItemList navigate="/" onClick={() => setDrawer(false)} text="Terms & Conditions" icon={<ListAltIcon />} />
            <ItemList navigate="/" onClick={() => setDrawer(false)} text="Support" icon={<SupportIcon />} />
          <ItemList navigate="/" onClick={() => setDrawer(false)} text="Contact us" icon={<LocalPhoneIcon />} /> */}
          </Box>
          <Box
            sx={{
              width: "100%",
              flex: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "flex-end",
              py: "5rem",
              px: "1rem",
            }}
          >
            {/* <BugButton /> */}
          </Box>
        </Drawer>
      </Box>
    </>
  );
}

function ItemList({
  text,
  icon,
  paddingLeft,
  onClick,
  navigate,
}: {
  text: string;
  icon: JSX.Element;
  paddingLeft?: number;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  navigate: string;
}) {
  return (
    <ListItem disablePadding>
      <ListItemButton
        component="a"
        href={navigate}
        onClick={e => {
          e.preventDefault();
          onClick && onClick(e);
        }}
        sx={{ paddingLeft: paddingLeft }}
      >
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
      </ListItemButton>
    </ListItem>
  );
}

function MultipleItemList({
  text,
  icon,
  paddingLeft,
  children,
}: {
  text: string;
  icon: JSX.Element;
  paddingLeft?: number;
  children: JSX.Element[];
}) {
  const [open, setOpen] = useState(true);

  return (
    <>
      <ListItemButton sx={{ paddingLeft: paddingLeft }} onClick={() => setOpen(!open)}>
        <ListItemIcon>{icon}</ListItemIcon>
        <ListItemText primary={text} />
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </>
  );
}
