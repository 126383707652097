import { Suspense } from "react";
import { useParams } from "../../hooks/useParams";
import { ParsePath } from "./routeTypes";
import ButtonScrollUp from "../../components/Buttons/ButtonScrollUp";

interface RouteParams<P extends string> {
  path: P;
  component: (params: ParsePath<P>) => any;
  scrollUpButton?: boolean;
}

export const Route = <P extends string>(props: RouteParams<P>) => {
  const params = useParams();
  const RouteComponent = props.component;

  return (
    <Suspense fallback={"Loading..."}>
      {
        <>
          <RouteComponent {...params} />
          {props.scrollUpButton && <ButtonScrollUp />}
        </>
      }
    </Suspense>
  );
};
