import TableHeader, { Status } from "../../components/Table/TableHeader";
import PrefixStatus from "../../components/PrefixStatus";
import { Prefix, PrefixsAll, useApi } from "@blindspot/bspot-api-lib";
import { useRecoilValue } from "recoil";
import { apiClient } from "../../state/atoms";
import useTenant from "../../hooks/useService";
import useReportError from "../../hooks/useReportError";
import { plural } from "../../utils/plural";
import { memo } from "react";
import BlindspotTable from "../../components/BlindspotTable";
import { useTranslation } from "react-i18next";
import { GridColDef } from "@mui/x-data-grid-pro";

const countAndParseStatuses = (prefixes: PrefixsAll): Status[] => {
  const acceptedCount = prefixes.filter(prefix => prefix.status === "accepted").length;
  const deniedCount = prefixes.filter(prefix => prefix.status === "denied").length;
  const underReviewCount = prefixes.filter(prefix => prefix.status === "review").length;

  const accepted = plural(
    acceptedCount,
    n => `${n} prefix accepted`,
    n => `${n} prefixes accepted`
  );
  const denied = plural(
    deniedCount,
    n => `${n} prefix denied`,
    n => `${n} prefixes denied`
  );
  const underReview = plural(
    underReviewCount,
    n => `${n} prefix under review`,
    n => `${n} prefixes under review`
  );

  return [
    { name: accepted, state: "ok", count: acceptedCount },
    { name: denied, state: "err", count: deniedCount },
    { name: underReview, state: "warn", count: underReviewCount },
  ];
};

let num = 0;
function createId() {
  num++;
  return num;
}

function CustomerIPPrefixes() {
  const tenant = useTenant();
  const api = useRecoilValue(apiClient);
  //const [prefixes, error] = useApi([api.get_prefixes(), tenant]);
  const { t } = useTranslation("index", { keyPrefix: "customeripprefixes" });

  const [prefixes, error] = useApi([api.get_prefixs_all(), tenant]);

  useReportError(error, "get_prefixes");

  return (
    <>
      <TableHeader
        tittle={t("title")}
        description={t("description")}
        // buttonUrl={"/all-ip-prefixes"}
        // buttonTittle={t("buttonTittle")}
        statuses={prefixes && countAndParseStatuses(prefixes)}
      />
      <PrefixTable prefixes={prefixes} error={error !== undefined} />
    </>
  );
}

function PrefixTable({ prefixes, error }: { prefixes: PrefixsAll | undefined; error: boolean }) {
  const { t } = useTranslation("index", { keyPrefix: "customeripprefixes" });
  const columns: GridColDef[] = [
    {
      headerName: t("ip_prefix"),
      field: "prefix",
      flex: 1,
    },
    {
      headerName: t("net_name"),
      field: "net_name",
      flex: 1,
    },
    {
      headerName: t("prefix_status"),
      field: "status",
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        { value: "accepted", label: "Accepted" },
        { value: "denied", label: "Denied" },
        { value: "review", label: "Review" },
      ],
      renderCell: ({ row }) => {
        if (row.prefix_status === "") return <></>;
        const body = row.prefix_status === "ACCEPTED"
          ? ["Accepted", t("body.prefix_status_ACCEPTED")]
          : row.prefix_status === "RPKI_INVALID"
            ? ["Denied", t("body.prefix_status_RPKI_INVALID")]
            : row.prefix_status === "OTHER_INVALID"
              ? ["Denied", t("body.prefix_status_OTHER_INVALID")] :
              row.prefix_status === "NO_ROA" ?
                ["Denied", t("body.prefix_status_NO_ROA")] :
                undefined;
        return body && <PrefixStatus status={body[0]} statusName={body[1]} />;
      },
    },
    {
      headerName: t("max"),
      field: "rpki_max_length",
      type: "number",
      align: "left",
      headerAlign: "left",
      flex: 1,
      valueFormatter: value => value ? "/" + value : undefined,
    },
    {
      headerName: t("source_as"),
      field: "as_name",
      flex: 1,
      renderCell: ({ row }) => (
        <>
          {row.as_number && "AS" + row.as_number}
          {row.as_number && row.asn_name && <br />}
          {row.asn_name}
        </>
      ),
    },
    {
      headerName: t("rpki"),
      field: "rpki_status",
      flex: 1,
      type: "singleSelect",
      valueOptions: [
        { label: "RPKI valid", value: "VALID" },
        { label: "RPKI ignore", value: "IGNORE" },
        { label: "RPKI invalid", value: "INVALID" },
        { label: "RPKI not fount", value: "NOT_FOUND" },
      ],
      valueFormatter: (value: any) => value,
    },
  ];
  return (
    <BlindspotTable
      sx={{ marginBottom: "2rem" }}
      error={error ? t("error") : undefined}
      rowHeight={65}
      noDataText={"No prefixes found"}
      rowsPerPage={5}
      rows={prefixes}
      columns={columns}
      getRowId={prefix => createId()}
    />
  );
}

export default memo(CustomerIPPrefixes);
