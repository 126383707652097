import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import TopCard, { CustomCardAction } from "./TopCard";
import { BGP, useApi } from "@blindspot/bspot-api-lib";
import { useRecoilValue } from "recoil";
import { apiClient } from "../state/atoms";
import useService from "../hooks/useService";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export default function ConnectionsCard() {
  const api = useRecoilValue(apiClient);
  const { t } = useTranslation("index", { keyPrefix: "cards.peace.connections" });
  const service = useService();

  const [connections, err, loading] = useApi([api.get_connections(), service]);

  const bgp = useMemo(() => connections?.reduce((acc, v) => [...v.bgp, ...acc], [] as Array<BGP>), [connections]);

  const established = useMemo(() => bgp && bgp.reduce((acc, v) => v.status === "ESTABLISHED" ? acc + 1 : acc, 0), [bgp]);

  const warn = useMemo(() => bgp && bgp.reduce((acc, v) => v.status === "ESTABLISHED" ? acc : acc + 1, 0), [bgp]);

  return (
    <TopCard
      isLoading={loading}
      status="normal"
      overline={t("title")}
      h5={established !== undefined ? `${established} ${t("count_alive", { count: established })}` : ""}
      dotStatuses={warn ? [{ state: established === 0 ? "err" : "warn", name: `${warn} connection down` }] : []}
      icon={
        established === 0 && warn !== 0 ?
          <ErrorOutlineIcon color="error" fontSize="large" sx={{ alignSelf: "flex-start" }} />
          : warn ? (
            <WarningAmberOutlinedIcon color="warning" fontSize="large" sx={{ alignSelf: "flex-start" }} />
          ) : (
            <CheckCircleOutlineOutlinedIcon color="success" fontSize="large" sx={{ alignSelf: "flex-start" }} />
          )
      }
    >
      <CustomCardAction linkToIdElement={{ text: t("link"), href: "#connections", color: "primary" }} />
    </TopCard>
  );
}
