import { Box, CardActions } from "@mui/material";
import { useTheme } from "@mui/material";
import { alpha } from "@mui/material";
import TopCard from "./TopCard";
import { useRecoilValue } from "recoil";
import { apiClient } from "../state/atoms";
import useService from "../hooks/useService";
import { useApi } from "@blindspot/bspot-api-lib";
import { useTranslation } from "react-i18next";
import useReportError from "../hooks/useReportError";

function calculatePercent(peak_4: number, peak_6: number, bs_limit: number) {
  const percent = ((peak_4 + peak_6) / (bs_limit || 0)) * 100;
  return percent > 100 ? 100 : percent;
}

export default function AttackErrCard() {
  const { t, i18n } = useTranslation("index", { keyPrefix: "cards" });
  const theme = useTheme();
  const api = useRecoilValue(apiClient);
  const service = useService();

  const [overview, err, loading] = useApi([api.get_overview(), service]);

  return (
    <TopCard
      isLoading={loading}
      status="attack"
      overline={t("under_attack.bandwidth_use.title")}
      h5={`${overview?.bandwidth_inuse} / ${overview?.bandwidth_maximum} Mbps`}
    >
      <CardActions sx={{ paddingX: "1rem", paddingY: "1rem" }}>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ width: "100%", backgroundColor: alpha(theme.palette.error.main, 0.08), borderRadius: "4px" }}>
            <Box
              sx={{
                width:
                  (overview
                    ? overview.bandwidth_inuse / overview.bandwidth_maximum
                    : "0") + "%",
                height: "4px",
                backgroundColor: theme.palette.error.main,
                borderRadius: "4px",
              }}
            />
          </Box>
        </Box>
      </CardActions>
    </TopCard>
  );
}
