import { Box, Button, Typography, useTheme } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import Link from "../../providers/router/Link";
import ErrorIcon from "@mui/icons-material/Error";
import AnchorGap from "../../components/AnchorGap";
import { useTranslation } from "react-i18next";
import { useApi } from "@blindspot/bspot-api-lib";
import { useRecoilValue } from "recoil";
import useReportError from "../../hooks/useReportError";
import useTenant from "../../hooks/useService";
import { apiClient } from "../../state/atoms";
import getNearestTimeUnit from "../../utils/getNearestTimeUnit";
import { formatBytes } from "../../utils/formatBytes";
import TableHeader from "../../components/Table/TableHeader";
import { GridColDef } from "@mui/x-data-grid-pro";
import BlindspotTable from "../../components/BlindspotTable";
import DataList from "../../components/Table/DataList";
import useService from "../../hooks/useService";
import { start } from "repl";
import { useMemo } from "react";

export default function AttackHistory() {
  const { t } = useTranslation("index", { keyPrefix: "attack_log" });
  const theme = useTheme();
  const { i18n } = useTranslation();

  const service = useService();
  const api = useRecoilValue(apiClient);

  const dates = useMemo(() => { return { start: new Date(0), end: new Date() } }, []);

  const [cmapigns, error] = useApi([api.get_campaigns(), {
    service: service,
    start: dates.start,
    end: dates.end,
    offset: 0,
    limit: 5
  }]);


  useReportError(error, "get_attack_log");

  const columns: GridColDef[] = [
    {
      headerName: t("attack_id"),
      field: "uuid",
      flex: 1,
      cellClassName: "wrap",
      width: 130,
      sortable: false,
      renderCell: ({ row }) => (
        <Link underline={"always"} href={`/campaigns/${row.uuid}`}>
          #{row.uuid.split("-").slice(-1)[0].toUpperCase()}
        </Link>
      ),
    },
    {
      headerName: t("status"),
      field: "mitigated",
      flex: 1,
      cellClassName: "wrap",
      sortable: false,
      renderCell: ({ row }) => (
        <Box sx={{ display: "flex", alignItems: "center" }}>
          {(row.mitigated && (
            <CheckCircleIcon color="success" sx={{ width: "20px", height: "20px", top: "2px", left: "2px" }} />
          )) || <ErrorIcon color="error" sx={{ width: "20px", height: "20px", top: "2px", left: "2px" }} />}
          <Typography marginLeft={0.75} variant="subtitle2">
            {t("body.status", { context: row.mitigated ? "mitigated" : "ongoing" })}
          </Typography>
        </Box>
      ),
    },
    {
      headerName: t("start_time"),
      field: "start",
      flex: 1,
      cellClassName: "wrap",
      align: "right",
      headerAlign: "right",
      sortable: false,
      renderCell: ({ row }) => (
        <>
          {new Date(row.start).toLocaleDateString(i18n.language)},
          <br />
          {new Date(row.start).toLocaleTimeString(i18n.language)}
        </>
      ),
    },
    {
      headerName: t("duration"),
      field: "duration",
      flex: 1,
      cellClassName: "wrap",
      align: "right",
      headerAlign: "right",
      sortable: false,
      renderCell: ({ row }) => getNearestTimeUnit(new Date(row.end).getTime() - new Date(row.start).getTime(), i18n.language),
    },
    {
      headerName: t("total_volume"),
      field: "total_volume",
      flex: 1,
      cellClassName: "wrap",
      align: "right",
      headerAlign: "right",
      sortable: false,
      renderCell: ({ row }) =>
        row.total_volume !== undefined
          ? `${formatBytes(row.total_volume, i18n.language, true)}`
          : t("no_data"),
    },
    {
      headerName: t("actions"),
      field: "actions",
      flex: 0.45,
      cellClassName: "wrap",
      align: "right",
      headerAlign: "right",
      width: 20,
      sortable: false,
      renderCell: ({ row }) => (
        <Link underline={"always"} href={`/campaigns/${row.uuid}`}>
          <Button variant="contained">
            {t("body.actions")}
          </Button>
        </Link>
      ),
    },
  ];

  return (
    <>
      <AnchorGap idTarget="attack_history" height={20} />
      <Box sx={{ marginTop: "6rem" }}>
        <TableHeader tittle={t("title")} buttonTittle={t("redirect_button")} buttonUrl={"attack_log"} />
        {/* <Grid container spacing={3} columns={12} sx={{ alignItems: "stretch" }}>
          <Grid item xs={8}> */}
        <BlindspotTable
          error={error !== undefined ? t("error") : undefined}
          disableColumnFilter
          disableColumnMenu
          rowHeight={92}
          rowsPerPage={5}
          rows={cmapigns}
          columns={columns}
          noDataText={t("no_attacks")}
          getRowId={rows => rows.uuid}
        />
        {/* </Grid> */}
        {/* <Grid item xs={4}>
            <Card
              sx={{
                border: 1,
                borderColor: "divider",
                paddingLeft: "0.75rem",
                paddingRight: "1rem",
                height: "100%",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box sx={{ width: "100%", height: "1rem" }}></Box>
              <Box my="1rem" sx={{ flex: 1 }}>
                {attacks !== undefined && attacks.length !== 0 ? (
                  <Bar
                    id="attack_log"
                    options={{
                      responsive: true,
                      maintainAspectRatio: false,
                      plugins: {
                        legend: {
                          labels: {
                            font: {
                              family: "DM Sans",
                            },
                          },
                          display: false,
                        },
                        datalabels: {
                          display: false,
                        },
                        tooltip: {
                          enabled: true,
                          callbacks: {
                            title(tooltipItems) {
                              return `${tooltipItems[0].label} | #${
                                attacks && attacks[tooltipItems[0].dataIndex].attack_id.split("-").slice(-1)[0]
                              }`;
                            },
                          },
                        },
                      },
                      scales: {
                        y: {
                          ticks: {
                            color: theme.palette.text.secondary,
                            callback: (label, tick, labels) =>
                              labels.length - 1 == tick ? "Mbps" : label.toLocaleString(i18n.language),
                          },
                          grid: {
                            tickColor: "transparent",
                            color: theme.palette.divider,
                            borderColor: "transparent",
                          },
                        },
                        x: {
                          ticks: {
                            color: theme.palette.text.secondary,
                          },
                          grid: {
                            offset: false,
                            color: theme.palette.divider,
                            borderColor: "transparent",
                            tickColor: "transparent",
                          },
                        },
                      },
                    }}
                    plugins={[
                      {
                        id: "chartAreaBorder",
                        beforeDraw(chart) {
                          const {
                            ctx,
                            chartArea: { left, top, right, bottom },
                          } = chart;
                          ctx.beginPath();
                          ctx.moveTo(left, top);
                          ctx.lineTo(left, bottom);
                          ctx.moveTo(right - 0.5, top + 0.5);
                          ctx.lineTo(right - 0.5, bottom - 0.5);
                          ctx.strokeStyle = theme.palette.divider;
                          ctx.stroke();
                        },
                      },
                    ]}
                    data={{
                      labels:
                        attacks &&
                        attacks
                          .filter((x, i) => i < 5)
                          .map(x =>
                            new Date(x.data.startTime).toLocaleTimeString(i18n.language, {
                              hour: "2-digit",
                              day: "2-digit",
                              month: "2-digit",
                              minute: "2-digit",
                            })
                          ),
                      datasets: [
                        {
                          data:
                            attacks &&
                            attacks
                              .filter((x, i) => i < 5)
                              .map(x =>
                                x.data.peak_bandwidth_ps.bytes
                                  ? Math.floor(x.data.peak_bandwidth_ps.bytes / 1_000_000)
                                  : 0
                              ),
                          backgroundColor: theme.palette.error.main,
                          borderRadius: 15,
                          barThickness: 6,
                        },
                      ],
                    }}
                  />
                ) : loading ? (
                  <Box sx={{ height: "100%", display: "flex", justifyContent: "center" }}>
                    <Box sx={{ height: "100%", display: "flex", justifyContent: "center", flexDirection: "column" }}>
                      <CircularProgress />
                    </Box>
                  </Box>
                ) : (
                  <CardContent sx={{ display: "grid", placeItems: "center", height: "100%" }}>
                    <Typography variant="subtitle1">No data present</Typography>
                  </CardContent>
                )}
              </Box>
              <Box sx={{ width: "100%", height: "1rem" }}></Box>
            </Card>
          </Grid> */}
        {/* </Grid> */}
      </Box>
    </>
  );
}
