import { atom, AtomEffect, selector } from "recoil";
import { Client, EmptyClient, IClient, MockClient } from "@blindspot/bspot-api-lib";
import { API_BASE_URI, MOCK_API_URL } from "../consts";
import { navigate } from "../utils/navigation";
import { getCookie } from "../utils/cookies";

export const jwt = atom<null | string>({
  key: "jwt",
  default: null,
});

const localStorageService = "serviceid";

const getService = () => {
  const service = localStorage.getItem(localStorageService);
  if (service === null) return null;
  const id = Number(service);
  if (isNaN(id)) {
    localStorage.removeItem(localStorageService);
    return null;
  }
  return id;
};

export const serviceState = atom({
  key: "service",
  default: getService(),
  effects: [
    ({ onSet }) =>
      onSet(val => {
        if (val === null) localStorage.removeItem(localStorageService);
        else {
          navigate("/");
          localStorage.setItem(localStorageService, val.toString());
        }
      }),
  ],
});

export const underAttack = atom({
  key: "underAttack",
  default: false,
});

export const isPrinting = atom({
  key: "isPrinting",
  default: false,
});

export const apiClient = selector<IClient>({
  key: "apiClient",
  get: ({ get }) => {
    const token = get(jwt);

    if (token === "MOCK_API" || window.location.hostname === MOCK_API_URL) return new MockClient();

    return token ? new Client(API_BASE_URI, token) : new EmptyClient();
  },
});

export type ThemeMode = "light" | "dark";

const toplevel = window.location.hostname.split(".").splice(-2).join(".");

const cookieSetEffect =
  (key: string): AtomEffect<ThemeMode> =>
    ({ onSet }) => {
      onSet(value => {
        document.cookie = `${key}=${value};domain=${toplevel};path=/`;
      });
    };

const getThemeState = () => {
  let state: ThemeMode = getCookie("theme") as ThemeMode;
  if (!state) {
    state = window.matchMedia("(prefers-color-scheme: dark)").matches ? "dark" : "light";
  }
  return state;
};

export const appThemeMode = atom<ThemeMode>({
  key: "ThemeMode",
  default: getThemeState(),
  effects: [cookieSetEffect("theme")],
});
