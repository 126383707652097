import { ReactElement, useMemo } from "react";
import { useRecoilValue } from "recoil";
import { createTheme, PaletteColor, ThemeProvider } from "@mui/material";
import { appThemeMode, ThemeMode } from "../state/atoms";
import shadows, { Shadows } from "@mui/material/styles/shadows";
import * as locales from "@mui/material/locale";
import { useTranslation } from "react-i18next";

declare module "@mui/material/styles" {
  interface BreakpointOverrides {
    xs: true;
    sm: true;
    md: true;
    lg: true;
    xl: true;
    xxl: true;
  }
}

interface Props {
  children: ReactElement;
}

declare module "@mui/material/styles" {
  interface Palette {
    greenGraph: PaletteColor;
  }
  interface PaletteOptions {
    greenGraph: PaletteColor;
  }

  interface Palette {
    purpleGraph: PaletteColor;
  }
  interface PaletteOptions {
    purpleGraph: PaletteColor;
  }

  interface Palette {
    tyrkisGraph: PaletteColor;
  }
  interface PaletteOptions {
    tyrkisGraph: PaletteColor;
  }

  interface Palette {
    yellowGraph: PaletteColor;
  }
  interface PaletteOptions {
    yellowGraph: PaletteColor;
  }
}

export function theme(mode: ThemeMode, locale: locales.Localization) {
  const { palette } = createTheme();
  return createTheme(
    {
      shadows: shadows.map(() => "none") as Shadows,
      typography: {
        fontFamily: ["DM Sans"].join(" ,"),
        h1: { fontSize: "3rem", fontWeight: "bold" },
        h2: { fontSize: "2.5rem", fontWeight: "bold" },
        h3: { fontSize: "2rem", fontWeight: "medium" },
        h4: { fontSize: "1.75rem", fontWeight: "medium" },
        h5: { fontSize: "1.5rem", fontWeight: "medium", "@media print": { fontSize: "1.2rem" } },
        h6: { fontSize: "1.125rem", fontWeight: "medium" },
        subtitle1: { fontWeight: "bold" },
        subtitle2: { fontWeight: "bold" },
      },
      breakpoints: {
        values: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1200,
          xl: 1536,
          xxl: 2240,
        },
      },
      palette: {
        mode,
        background: {
          default: mode === "light" ? "#F4F4F4" : "#151515",
        },
        primary:
          mode === "light"
            ? {
                main: "#00897B",
                dark: "#00695C",
                light: "#42A5F5",
                contrastText: "#FFFFFF",
              }
            : {
                main: "#00BFA5",
                dark: "#009984",
                light: "#E3F2FD",
                contrastText: "rgba(0, 0, 0, 0.87)",
              },
        secondary:
          mode === "light"
            ? {
                main: "#000000",
                dark: "#000000",
                light: "rgba(0, 0, 0, 0.60)",
                contrastText: "#FFFFFF",
              }
            : {
                main: "#FFFFFF",
                dark: "rgba(255, 255, 255, 0.87)",
                light: "#FFFFFF",
                contrastText: "rgba(0, 0, 0, 0.87)",
              },
        error:
          mode === "light"
            ? {
                main: "#DC2625",
                dark: "#991B1B",
                light: "#EF5350",
                contrastText: "#FFFFFF",
              }
            : {
                main: "#FF1744",
                dark: "#D32F2F",
                light: "#E57373",
                contrastText: "#FFFFFF",
              },
        warning:
          mode === "light"
            ? {
                main: "#FFA000",
                dark: "#FF6F00",
                light: "#FF9800",
                contrastText: "#000000",
              }
            : {
                main: "#FFC400",
                dark: "#F57C00",
                light: "#FFB74D",
                contrastText: "rgba(0, 0, 0, 0.87)",
              },
        info:
          mode === "light"
            ? {
                main: "#0277BD",
                dark: "#01579B",
                light: "#03A9F4",
                contrastText: "#FFFFFF",
              }
            : {
                main: "#00B0FF",
                dark: "#0288D1",
                light: "#4FC3F7",
                contrastText: "rgba(0, 0, 0, 0.87)",
              },
        success:
          mode === "light"
            ? {
                main: "#2E7D32",
                dark: "#1B5E20",
                light: "#4CAF50",
                contrastText: "#FFFFFF",
              }
            : {
                main: "#66BB6A",
                dark: "#388E3C",
                light: "#00E676",
                contrastText: "rgba(0, 0, 0, 0.87)",
              },
        action:
          mode === "light"
            ? {
                hover: "rgba(0, 0, 0, 0.04)",
                selected: "rgba(0, 0, 0, 0.8)",
                disabled: "rgba(0, 0, 0, 0.26)",
                disabledBackground: "rgba(0, 0, 0, 0.12)",
                focus: "rgba(0, 0, 0, 0.12)",
              }
            : {
                hover: "rgba(255, 255, 255, 0.08)",
                selected: "rgba(255, 255, 255, 0.16)",
                disabled: "rgba(255, 255, 255, 0.3)",
                disabledBackground: "rgba(255, 255, 255, 0.12)",
                focus: "rgba(255, 255, 255, 0.12)",
              },
        greenGraph: palette.augmentColor(
          mode === "light"
            ? {
                color: {
                  main: "#6da062",
                },
              }
            : {
                color: {
                  main: "#7eb972",
                },
              }
        ),
        purpleGraph: palette.augmentColor(
          mode === "light"
            ? {
                color: {
                  main: "#a922d1",
                },
              }
            : {
                color: {
                  main: "#ca4cf3",
                },
              }
        ),
        tyrkisGraph: palette.augmentColor(
          mode === "light"
            ? {
                color: {
                  main: "#4aa390",
                },
              }
            : {
                color: {
                  main: "#6bc3af",
                },
              }
        ),
        yellowGraph: palette.augmentColor(
          mode === "light"
            ? {
                color: {
                  main: "#d9bd67",
                },
              }
            : {
                color: {
                  main: "#fade88",
                },
              }
        ),
      },
    },
    locale
  );
}

function AppThemeProvider({ children }: Props): ReactElement {
  const mode = useRecoilValue(appThemeMode);
  const { i18n } = useTranslation();
  const locale = Object.entries(locales).filter(lang =>
    lang[0].toLocaleLowerCase().includes(i18n.language.toLocaleLowerCase())
  )[0][1];
  const theme_ = useMemo(() => theme(mode, locale), [mode, locale]);
  return <ThemeProvider theme={theme_}>{children}</ThemeProvider>;
}

export default AppThemeProvider;
