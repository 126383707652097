import { Grid } from "@mui/material";
import TrafficGraph from "../../components/TrafficGraph";
import AttackOkCard from "../../components/AttackOkCard";
import AttackErrCard from "../../components/AttackErrCard";
import AttackHistoryOkCard from "../../components/AttackHistoryOkCard";
import AttackHistoryErrCard from "../../components/AttackHistoryErrCard";
import ConnectionsErrCard from "../../components/ConnectionsErrCard";
import ConnectionsCard from "../../components/ConnectionsCard";
import BGP from "../../components/BGP";
import BGPErr from "../../components/BGPErr";
import AttacklogErr from "./AttackLogErr";
import useService from "../../hooks/useService";
import { useApi } from "@blindspot/bspot-api-lib";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { apiClient, underAttack } from "../../state/atoms";
import { useEffect } from "react";

export default function Graphs() {
  const service = useService();
  const api = useRecoilValue(apiClient);

  const [overview, error, loading] = useApi([
    api.get_overview(), service
  ]);

  const setUnderAttack = useSetRecoilState(underAttack);

  useEffect(() => {
    overview && setUnderAttack(overview.ongoing_attacks_count > 0);
  }, [(overview?.ongoing_attacks_count || 0) > 0]);

  return (
    <>
      <Grid container spacing="1.5rem" columns={4} sx={{ alignItems: "stretch" }}>
        <Grid item xs={1}>
          {overview && overview.ongoing_attacks_count > 0 ? <AttackErrCard overview={overview} loading={loading} /> : <AttackOkCard loading={loading} />}
        </Grid>
        <Grid item xs={1}>
          {overview && overview.ongoing_attacks_count > 0 ? <AttackHistoryErrCard overview={overview} loading={loading} /> : <AttackHistoryOkCard />}
        </Grid>
        <Grid item xs={1}>
          {overview && overview.ongoing_attacks_count > 0 ? <ConnectionsErrCard overview={overview} loading={loading} /> : <ConnectionsCard />}
        </Grid>
        <Grid item xs={1}>
          {overview && overview.ongoing_attacks_count > 0 ? <BGPErr /> : <BGP />}
        </Grid>
      </Grid>
      <Grid item xs={2} sx={{ alignItems: "stretch", marginY: "1.5rem" }}>
        <TrafficGraph />
      </Grid>

      {/* {overview && overview.ongoing_attacks_count ? <AttacklogErr error={error !== undefined} attacks={attacks} /> : <></>} */}
    </>
  );
}
