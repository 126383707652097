import { Box, Button, useTheme } from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useEffect, useRef, useState } from "react";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  box: {
    position: "fixed",
    bottom: 0,
    opacity: 0,
    right: 0,
    margin: "2.5rem",
    zIndex: 99,
    "@media print": { display: "none" },
  },
  popIn: {
    animationName: "$popIn",
    animationDuration: "0.5s",
    animationFillMode: "forwards",
  },
  popOut: {
    animationName: "$popOut",
    animationDuration: "0.5s",
    animationFillMode: "forwards",
  },
  "@keyframes popIn": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },

  "@keyframes popOut": {
    "0%": {
      opacity: 1,
    },
    "100%": {
      opacity: 0,
    },
  },
}));

export default function ButtonScrollUp() {
  const classes = useStyles();
  const theme = useTheme();
  const [isVisible, setVisible] = useState<"shown" | "hidden">("hidden");
  const [firstLoaded, setFirstLoaded] = useState(true);
  const domRef = useRef();

  useEffect(() => {
    isVisible === "shown" && setFirstLoaded(false);
  }, [isVisible]);

  useEffect(() => {
    window.onscroll = () => {
      if (
        document.body.scrollTop > document.body.scrollHeight / 2.2 ||
        document.documentElement.scrollTop > document.body.scrollHeight / 2.2
      ) {
        setVisible("shown");
      } else {
        setVisible("hidden");
      }
    };
  }, []);

  return (
    <Box
      className={`${classes.box} ${
        !firstLoaded && ((isVisible === "shown" && classes.popIn) || (isVisible === "hidden" && classes.popOut))
      }`}
      ref={domRef}
    >
      <Button
        onClick={() => window.scrollTo(0, 0)}
        variant="contained"
        sx={{ padding: "0.8rem", marginTop: "4rem", minWidth: "auto" }}
        aria-label="scroll up"
      >
        <KeyboardArrowUpIcon fontSize="large" sx={{ color: theme.palette.background.default }} />
      </Button>
    </Box>
  );
}
