/* eslint-disable  @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { Box, BoxProps, LinearProgress, Paper, Skeleton, SxProps, Typography, useTheme } from "@mui/material";
import Pagination from "./Pagination";
import {
  DataGridPro,
  DataGridProProps,
  GridColDef,
  gridColumnPositionsSelector,
  gridColumnsTotalWidthSelector,
  useGridApiContext,
} from "@mui/x-data-grid-pro";

type OmittedFields =
  | "columns"
  | "rows"
  | "getRowId"
  | "disableSelectionOnClick"
  | "hideFooter"
  | "autoHeight"
  | "pageSize"
  | "onPageChange"
  | "paginationModel"
  | "pageSizeOptions";

interface BlindspotTableProps<T> extends Omit<DataGridProProps, OmittedFields> {
  rows: T[] | undefined;
  columns: GridColDef[];
  rowsPerPage: number;
  page?: number;
  getRowId: (row: T) => string | number;
  onPageChange?: (page: number) => void;
  rowsPerPageOptions?: number[];
  onRowsPerPageChange?: (rowsPerPage: number) => void;
  noDataText?: string;
  error?: string;
  sx?: SxProps;
  loading?: boolean;
}

// TODO: handling of rendering paging.

export default function BlindspotTable<T>({
  rows,
  columns,
  error,
  getRowId,
  rowHeight,
  rowsPerPage: initRowsPerPage,
  rowsPerPageOptions,
  onRowsPerPageChange,
  paginationMode = "client",
  page: initPage,
  noDataText,
  onPageChange,
  sx,
  rowCount,
  loading,
  ...rest
}: BlindspotTableProps<T>) {
  const [page, setPage] = useState(initPage ?? 0);
  const [rowsPerPage, setRowsPerPage] = useState(initRowsPerPage);

  useEffect(() => {
    setRowsPerPage(initRowsPerPage);
  }, [initRowsPerPage]);

  const theme = useTheme();

  useEffect(() => {
    if (rows === undefined) return;
    setPage(initPage ?? 0);
    onPageChange && onPageChange(initPage ?? 0);
  }, [rows?.length]);

  const isSmall = rows && rows.length > 0 && initRowsPerPage > rows.length;
  const smallestRowsPerPageOption = Math.min(...(rowsPerPageOptions || [rowsPerPage]));
  const isLastPage = rows && page === Math.floor(rows.length / initRowsPerPage);

  return (
    <Box sx={sx}>
      <Paper sx={{ height: isSmall ? "auto" : `${56 + (rowHeight || 0) * rowsPerPage + 1.8}px` }}>
        <DataGridPro
          paginationMode={paginationMode}
          rowHeight={rowHeight}
          scrollbarSize={0}
          classes={{ menu: "datagrid_menu_shadow" }}
          slots={{
            loadingOverlay: error ? (ErrorLoadingOverlay as any) : (SkeletonLoadingOverlay as any),
            noRowsOverlay: NoRowsOverlay as any,
          }}
          slotProps={{
            loadingOverlay: { text: error } as any,
            noRowsOverlay: { text: noDataText } as any,
          }}
          sx={{
            "& div[role=grid]": {
              "@media print": {
                border: "0",
              },
            },

            "& .MuiDataGrid-row": {
              "@media print": {
                display: "flex !important",
                borderBottom: "1 solid white !important",
                width: "100% !important",
              },
            },
            "@media print": {
              display: "grid",
            },
            "& .MuiDataGrid-columnHeaders": {
              "@media print": {
                width: "100% !important",
              },
            },
            "div[role=rowgroup]": {
              "@media print": {
                width: "100% !important",
              },
            },
            "& .MuiDataGrid-container--top [role=row]": {
              backgroundColor: "inherit",
              "@media print": {
                width: "100% !important",
                display: "flex !important",
              },
            },
            "& .MuiDataGrid-root": {
              overflow: "hidden",
            },
            "& .MuiDataGrid-columnSeparator": {
              display: "none",
            },
            "& .MuiDataGrid-row:hover": {
              backgroundColor: theme.palette.background.default,
            },
            "& .MuiDataGrid-cell": {
              lineHeight: "inherit",
              display: "grid",
              placeItems: "center",
            },
            "& div[role=columnheader], div[role=gridcell]": {
              paddingX: "1rem",
              "@media print": {
                minWidth: "inherit !important",
                maxWidth: "inherit !important",
                width: "14.3% !important",
              },
            },

            "& div[role=cell]": {
              paddingY: "0.875rem",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell:focus-within": {
              outline: "none !important",
            },
            "& .MuiDataGrid-columnHeader:focus-within": {
              outline: "none !important",
            },
            ".noBorder": {
              border: "none",
            },
            ".customDivider": {
              position: "relative",
              borderTop: "none",
              overflow: "visible !important",
            },
            ".customDivider::before": {
              //eslint-disable-next-line quotes
              content: '" "',
              width: "100%",
              height: "1px",
              background: "rgba(81, 81, 81, 1)",
              position: "absolute",
              top: "-1px",
              left: "2rem",
            },
          }}
          paginationModel={{ page, pageSize: rowsPerPage }}
          getRowId={getRowId as any}
          loading={rows === undefined || loading}
          columns={columns}
          disableRowSelectionOnClick
          hideFooter
          rowCount={rowCount}
          pagination
          autoHeight={!isLastPage}
          rows={error ? [] : (rows as any) || []}
          {...rest}
        />
      </Paper>
      {rows && (paginationMode === "server" || rows.length > smallestRowsPerPageOption) ? (
        <Pagination
          onRowsPerPageChange={rpp => {
            setRowsPerPage(rpp);
            onRowsPerPageChange && onRowsPerPageChange(rpp);
          }}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={rowsPerPageOptions}
          pageCount={rowCount || rows.length}
          page={page}
          setPage={p => {
            setPage(p);
            onPageChange && onPageChange(p);
          }}
        />
      ) : (
        <Box height="52px"></Box>
      )}
    </Box>
  );
}

// Pseudo random number. See https://stackoverflow.com/a/47593316
function mulberry32(a: number): () => number {
  return () => {
    /* eslint-disable */
    let t = (a += 0x6d2b79f5);
    t = Math.imul(t ^ (t >>> 15), t | 1);
    t ^= t + Math.imul(t ^ (t >>> 7), t | 61);
    return ((t ^ (t >>> 14)) >>> 0) / 4294967296;
    /* eslint-enable */
  };
}

function randomBetween(seed: number, min: number, max: number): () => number {
  const random = mulberry32(seed);
  return () => min + (max - min) * random();
}

function SkeletonCell({ sx, ...rest }: BoxProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        borderBottom: `1px solid ${theme.palette.divider}`,
        ...sx,
      }}
      {...rest}
    />
  );
}

interface ErrorLoadingOverlayProps {
  text?: string;
}

function ErrorLoadingOverlay({ text = "Error occurred" }: ErrorLoadingOverlayProps) {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
      }}
    >
      <Typography sx={{ mt: 2 }}>{text}</Typography>
    </Box>
  );
}

function SkeletonLoadingOverlay() {
  const apiRef = useGridApiContext();

  const dimensions = apiRef.current?.getRootDimensions();
  const viewportHeight = dimensions?.viewportInnerSize.height ?? 0;

  // @ts-expect-error Function signature expects to be called with parameters, but the implementation suggests otherwise
  const rowHeight = apiRef.current.unstable_getRowHeight();
  const skeletonRowsCount = Math.ceil(viewportHeight / rowHeight);

  const totalWidth = gridColumnsTotalWidthSelector(apiRef);
  const positions = gridColumnPositionsSelector(apiRef);
  const inViewportCount = React.useMemo(
    () => positions.filter(value => value <= totalWidth).length,
    [totalWidth, positions]
  );
  const columns = apiRef.current.getVisibleColumns().slice(0, inViewportCount);

  const children = React.useMemo(() => {
    // reseed random number generator to create stable lines betwen renders
    const random = randomBetween(12345, 25, 75);
    const array: React.ReactNode[] = [];

    for (let i = 0; i < skeletonRowsCount; i += 1) {
      for (const column of columns) {
        const width = Math.round(random());
        array.push(
          <SkeletonCell key={`column-${i}-${column.field}`} sx={{ justifyContent: column.align }}>
            <Skeleton sx={{ mx: 2 }} width={`${width}%`} />
          </SkeletonCell>
        );
      }
      array.push(<SkeletonCell key={`fill-${i}`} />);
    }
    return array;
  }, [skeletonRowsCount, columns]);

  const rowsCount = apiRef.current.getRowsCount();

  const scrollRef = React.useRef(null);

  React.useEffect(() => {
    const handleScrollChange = (params: any) => {
      if (scrollRef.current) {
        (scrollRef.current as any).scrollLeft = params.left;
      }
    };
    // The `subscribeEvent` method will automatically unsubscribe in the cleanup function of the `useEffect`.
    return apiRef.current.subscribeEvent("scrollPositionChange", handleScrollChange);
  }, [apiRef]);

  return rowsCount > 0 ? (
    <LinearProgress />
  ) : (
    <div
      ref={scrollRef}
      style={{
        display: "grid",
        gridTemplateColumns: `${columns.map(({ computedWidth }) => `${computedWidth}px`).join(" ")} 1fr`,
        gridAutoRows: rowHeight,
        overflow: "hidden",
      }}
    >
      {children}
    </div>
  );
}

interface NoRowsOverlayProps {
  text?: string;
}

function NoRowsOverlay({ text = "No Rows" }: NoRowsOverlayProps) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        "& .ant-empty-img-1": {
          fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
        },
        "& .ant-empty-img-2": {
          fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
        },
        "& .ant-empty-img-3": {
          fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
        },
        "& .ant-empty-img-4": {
          fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
        },
        "& .ant-empty-img-5": {
          fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
          fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
        },
      }}
    >
      <svg width="120" height="100" viewBox="0 0 184 152" aria-hidden focusable="false">
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse className="ant-empty-img-5" cx="67.797" cy="106.89" rx="67.797" ry="12.668" />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Typography sx={{ mt: 2 }}>{text}</Typography>
    </Box>
  );
}
