import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import TopCard, { CustomCardAction } from "./TopCard";
import { useRecoilValue } from "recoil";
import { apiClient } from "../state/atoms";
import useService from "../hooks/useService";
import { BGP as BGP_type, useApi } from "@blindspot/bspot-api-lib";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";

export default function BGP() {
  const api = useRecoilValue(apiClient);
  const { t } = useTranslation("index", { keyPrefix: "cards.peace.BGP_prefixes" });
  const service = useService();

  const [prefixies, err, loading] = useApi([api.get_prefixies_active(), service]);


  const accepted = prefixies?.filter(prefix => prefix.status === "ACCEPTED" || prefix.status === "ANNOUNCED").length;
  const rejected = prefixies?.filter(prefix => prefix.status === "REJECTED").length;
  const announced = prefixies?.filter(prefix => prefix.status === "ANNOUNCED").length;

  return (
    <TopCard
      isLoading={loading}
      status="normal"
      overline={t("title")}
      h5={accepted !== undefined ? `${accepted} ${t("count_propagated", { count: accepted })}` : ""}
      dotStatuses={[{ state: "warn", name: `${announced} announced, ${rejected} rejected` }]}
      icon={
        rejected ? (
          <WarningAmberOutlinedIcon color="warning" fontSize="large" sx={{ alignSelf: "flex-start" }} />
        ) : (
          <CheckCircleOutlineOutlinedIcon color="success" fontSize="large" sx={{ alignSelf: "flex-start" }} />
        )
      }
    >
      <CustomCardAction linkToIdElement={{ text: t("link"), href: "#ip_prefixes", color: "primary" }} />
    </TopCard>
  );
}
